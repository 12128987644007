import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { useStaticQuery, graphql } from "gatsby"

import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons"

const Link_Container = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 0 10px 0;
    width: 100%;

    a {
        position: relative;
        color: inherit;
        margin: 10px;

        & > .o-icon {
            display: inline-block;
            vertical-align: middle;
            box-sizing: inherit;
            font-size: inherit;
        }

        & > .o-icon-circle {
            width: calc(100% + 1.5rem);
            height: calc(100% + 1.5rem);
            position: absolute;
            left: -0.75rem;
            top: -0.6rem;

            circle {
                fill: none;
                stroke-width: 2px;
                stroke: currentColor;
                stroke-linecap: round;
                stroke-dasharray: 88;
                stroke-dashoffset: 88;
                transform: rotate(-180deg);
                transform-origin: 16px 16px;
                transition: stroke-dashoffset 0.5s, transform 0.4s;
            }

            &:hover {
                circle {
                    stroke-dashoffset: 0;
                    transform: rotate(0deg);
                }
            }
        }
    }
`

const Span = styled.div`
        display: inline-block;
        width: 150px;
        border-top: 3px solid;
        margin: 0 auto 16px;
`

export default function Team() {
    const data = useStaticQuery(
        graphql`
            query {
                googleSheet {
                    Team {
                        mail
                        name
                        title
                        linkedin
                        instagram
                    }
                }
            }
        `
    )

    return (
        <div>
            { data.googleSheet.Team.map(member => (
                <div key={member.name}>
                    <h2 tabIndex="0" style={{ fontSize: "20px", fontWeight: "700", marginBottom: "0px" }}>{member.name} - {member.title}</h2>
                    <Link_Container>
                        { member.instagram !== null &&
                            <OutboundLink aria-label={`${member.name} Instagram`} href={member.instagram} rel="noreferrer" target="_blank">
                                <FontAwesomeIcon icon={faInstagram} className="o-icon" />
                                <svg className="o-icon-circle" viewBox="0 0 32 32">
                                    <circle cx="16" cy="16" r="14"></circle>
                                </svg>
                            </OutboundLink>
                        }
                        { member.linkedin !== null &&
                            <OutboundLink aria-label={`${member.name} Linkedin`} href={member.linkedin} rel="noreferrer" target="_blank">
                                <FontAwesomeIcon icon={faLinkedin} className="o-icon" />
                                <svg className="o-icon-circle" viewBox="0 0 32 32">
                                    <circle cx="16" cy="16" r="14"></circle>
                                </svg>
                            </OutboundLink>
                        }
                        { member.mail !== null &&
                            <OutboundLink aria-label={`${member.mail} mail`} href={`mailto:${member.mail}`} rel="noreferrer">
                                <FontAwesomeIcon icon={faEnvelope} className="o-icon" />
                                <svg className="o-icon-circle" viewBox="0 0 32 32">
                                    <circle cx="16" cy="16" r="14"></circle>
                                </svg>
                            </OutboundLink>
                        }
                    </Link_Container>
                    <Span />
                </div>
            ))}
        </div>
    )
}
