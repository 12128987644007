import React from "react"
import { FormattedMessage as I18n } from "gatsby-plugin-react-intl"
import styled from "@emotion/styled"

import Menu from "../components/Menu"
import SocialLinks from "../components/SocialLinks"

const Container = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light);
    color: var(--dark);
    border: 0.5rem solid;
    height: 15vh;
    width: calc(50% - 36px);
    padding: 5px 10px 5px 10px;
    display: grid;
    grid-template-columns: 25% 42% 33%;

    @media screen and (max-width: 768px) {
        width: calc(100% - 36px);
        grid-template-columns: 50% 50%;
    }

    & > .mobile {
        padding: 10px;
        justify-self: center;
        align-self: center;


        h2  {
            font-weight: 800;
            font-size: 20px;
            color: var(--dark);
            margin: 0 0 0 0;
        }

        @media screen and (max-width: 768px) {
            display: none
        }
    }
`

export default function Label() {
    return (
        <Container>
                <div><Menu /></div>
                <div className="mobile">
                    <h2><I18n id="header.title2" defaultMessage={"default"} /></h2>
                    <h2><I18n id="header.title3" defaultMessage={"default"} /></h2>
                    <h2><I18n id="header.title4" defaultMessage={"default"} /></h2>
                </div>
                <SocialLinks />
        </Container>
    )
}
