import React from "react"
import { FormattedMessage as I18n } from "gatsby-plugin-react-intl"
import { Link } from "gatsby"

import styled from "@emotion/styled"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

import LanguageSelector from "./LanguageSelector"
import Team from "../screen/Team"
import logo_blz from "../images/logo_blz.svg"

const Overlay = styled.div`
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: ${props => props.open ? "100%" : "0%"};
    transition: 0.5s;
    overflow: hidden;
    background-color: var(--dark);
    color: var(--light);
    text-align: center;

	a {
		color: inherit;
        text-decoration: inherit;
        margin: 0.5rem 1rem;
    }

    & > menu-container {
        display: flex;
        height: 100%;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
`

const Button = styled.button`
    position: fixed;
    padding: 0;
    border: 0;
    z-index: 10;
    background-color: transparent;
    font-size: 25px;
    color: var(--light);

    & > .image {
        position: inherit;
        right: 0;
        height: 8vh;
        width: 8vh;
        object-fit: scale-down;
    }

    & > .svg {
        position: relative;
        width: 15vh;
        height: 100%;
        object-fit: scale-down;
        object-position: 0% top;
        display: inline-block;
        vertical-align: middle;

        &: hover {
            filter: invert(38%) sepia(8%) saturate(17%) hue-rotate(314deg) brightness(91%) contrast(84%);
        }
    }
`

export default function Menu() {
    const [open, setOpen] = React.useState(false)

    const toggleOpen = () => {
        setOpen(!open)
    }

    return (
        <>
            <Button aria-label="the blz team" onClick={toggleOpen}>
                { open ?
                    <FontAwesomeIcon icon={faTimes} />
                :
                    <img className="svg" alt="logo of blz company" src={logo_blz} />
                }
            </Button>
            <Overlay open={open}>
                <menu-container>
                    <Team />
                    <div>
                        <LanguageSelector />
                        <Link to="/legal" style={{ fontSize: "16px", color: "var(--light)" }}>
                            <I18n id="legal.title" defaultMessage={"a leading"} />
                        </Link>
                    </div>
                </menu-container>
            </Overlay>
        </>
    )
}
