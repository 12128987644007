import React from "react"

import Layout from "../components/layout"
import Artists from "../screen/Artists"
import Label from "../screen/Label"

export default function Home() {
  return (
    <Layout>
      <Artists />
      <Label />
    </Layout>
  )
}
